import React from "react";
import { Col, Container, NavLink, Row } from "react-bootstrap";
import "./Footer.css";

const Footer = ({ landingPageData, id }) => {
  return (
    <div className="footer-bg">
      <Container>
        <Row className="text-white">
          <Col xs={6} md={3}>
            <div className="single-footer-widget">
              <div className="widget-title">
                <h2>Practices</h2>
              </div>
              <div className="widget-content">
                <NavLink className="footer-link">For Patients</NavLink>
                <NavLink className="footer-link">FAQ's</NavLink>
                <NavLink className="footer-link" href={`/about?id=${id}`}>
                  About
                </NavLink>
                <NavLink className="footer-link" href={`/contact?id=${id}`}>
                  Contact Us
                </NavLink>
                <NavLink className="footer-link">Blog</NavLink>
              </div>
            </div>
          </Col>
          <Col xs={6} md={3}>
            <div className="single-footer-widget">
              <div className="widget-title">
                <h2>Resources</h2>
              </div>
              <div className="widget-content">
                <NavLink className="footer-link">New Patients</NavLink>
                <NavLink className="footer-link">Meet the Team</NavLink>
                <NavLink className="footer-link">Patient Form</NavLink>
                <NavLink className="footer-link">Insurance</NavLink>
                <NavLink className="footer-link">Account Login</NavLink>
              </div>
            </div>
          </Col>
          <Col xs={6} md={3}>
            <div className="single-footer-widget">
              <div className="widget-title">
                <h2>Services</h2>
              </div>
              <div className="widget-content">
                <NavLink className="footer-link">Dental Dictionary</NavLink>
                <NavLink className="footer-link">Dental Sealands</NavLink>
                <NavLink className="footer-link">Dental Implants</NavLink>
                <NavLink className="footer-link">General Dentistry</NavLink>
                <NavLink className="footer-link">Sedation Dentistry</NavLink>
              </div>
            </div>
          </Col>
          <Col xs={6} md={3}>
            <div className="single-footer-widget">
              <div className="widget-title">
                <h2>Our Address</h2>
              </div>
              <div className="widget-content">
                {landingPageData?.Contact?.address && (
                  <NavLink
                    className="footer-link"
                    href={`https://www.google.com/search?q=${landingPageData?.Contact?.address}`}
                  >
                    {landingPageData?.Contact?.address}
                  </NavLink>
                )}
                {/* <NavLink className="footer-link">Village Dr Mason, NY.</NavLink> */}
                {landingPageData?.Contact?.phone && (
                  <NavLink
                    className="footer-link"
                    href={`tel:${landingPageData?.Contact?.phone}`}
                  >
                    Phone: {landingPageData?.Contact?.phone}
                  </NavLink>
                )}
                {landingPageData?.Contact?.email && (
                  <NavLink
                    className="footer-link"
                    href={`mailto:${landingPageData?.Contact?.email}`}
                  >
                    Email: {landingPageData?.Contact?.email}
                  </NavLink>
                )}
                {/* <NavLink className="footer-link">Fax:+1 675 5867 340</NavLink> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="footer-copy-right text-center text-white">
        <p className="mb-0">
          &copy; 2024 -{" "}
          <span className="developer">{landingPageData?.WebsiteName}</span> |
          All Rights Reserved | Made by{" "}
          <a href="https://asambhav.in" style={{ textDecoration: "none" }}>
            <span className="developer">Asambhav</span>
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
