import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import "./Services.css";

const Services = (props) => {
  const { name, text, link, icon } = props.treatment;
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    AOS.refresh();
  }, []);
  return (
    <>
      <Col md={6} lg={6} xl={4} xs={12} style={{ marginBottom: "30px" }}>
        <div className="single-service-box" data-aos="flip-left">
          <div className="service-icon">
            <img src={icon} alt="" />
          </div>
          <h3>{name}</h3>
          <p>{text}</p>
          <a href=".#">{link}</a>
        </div>
      </Col>
    </>
  );
};

export default Services;
