import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Services from "../../Pages/Home/Services/Services";

const Service = ({ focus }) => {
  const images = [
    "https://i.ibb.co/GQmKHnh/heart.png",
    "https://i.ibb.co/N2M8n97/love.png",
    "https://i.ibb.co/t2cczRh/outcome.png",
  ];

  const focusWithImages = focus.map((foc, index) => ({
    ...foc,
    icon: images[index % images.length],
  }));
  return (
    <section className="service-wrapper">
      <Container>
        <Row>
          <Col sm={12}>
            <div className="section-title text-center">
              <h1>Patient Focused Approach</h1>
            </div>
          </Col>
        </Row>
        <Row>
          {focusWithImages.map((treatment) => (
            <Services key={treatment.id} treatment={treatment} />
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Service;
