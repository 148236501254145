import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Doctors from "../../Pages/Home/Doctors/Doctors";

const Dentist = ({ team }) => {
  const images = [
    "/assets/images/dentist_1.jpg",
    "/assets/images/dentist_2.jpg",
    "/assets/images/dentist_3.jpg",
  ];

  const dentistsWithImages = team.map((dentist, index) => ({
    ...dentist,
    img: images[index % images.length],
  }));

  return (
    <section className="doctor-wrapper">
      <Container>
        <Row>
          <Col sm={12}>
            <div className="section-title">
              <h1 className="mb-5 mb-sm-dent">Our Dentists</h1>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center g-3">
          {dentistsWithImages.map((dentist, index) => (
            <Doctors key={index} dentist={dentist} />
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Dentist;
