import { Container, Row } from "react-bootstrap";
import Features from "../../Pages/Home/Features/Features";

const Feature = ({ features }) => {
  const images = [
    "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature1.svg",
    "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature2.svg",
    "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature3.svg",
  ];

  const numFeaturesToDisplay = Math.min(
    features.length,
    Math.floor(features.length / 3) * 3
  );
  const featuresToDisplay = features.slice(0, numFeaturesToDisplay);

  const featuresWithImages = featuresToDisplay.map((feature, index) => ({
    ...feature,
    icon: images[index % images.length],
  }));

  return (
    <section className="feature-wrapper">
      <Container>
        <Row className="justify-content-md-center g-3">
          {featuresWithImages.map((feature, index) => (
            <Features key={index} feature={feature}></Features>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Feature;
