import { faEnvelope, faHome, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./ContactUs.css";

const ContactUs = ({ contact }) => {
  return (
    <section className="contactUs-wrapper">
      <Container>
        <Row>
          {contact?.address && (
            <Col md={6} lg={6} xl={4}>
              <a
                href={`https://www.google.com/search?q=${contact?.address}`}
                className="single-contact icon1"
                target="blank"
              >
                <div className="c-icon" style={{}}>
                  <FontAwesomeIcon icon={faHome} />
                </div>
                <div className="c-info text-start width-80">
                  <h4>Address</h4>
                  <p>{contact?.address}</p>
                </div>
              </a>
            </Col>
          )}
          {contact?.email && (
            <Col md={6} lg={6} xl={4}>
              <a
                href={`mailto:${contact?.email}`}
                className="single-contact icon2"
              >
                <div className="c-icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <div className="c-info text-start width-80">
                  <h4>Email</h4>
                  <p>{contact?.email}</p>
                </div>
              </a>
            </Col>
          )}
          {contact?.phone && (
            <Col md={6} lg={6} xl={4}>
              <a
                href={`tel:${contact?.phone}`}
                className="single-contact icon3"
              >
                <div className="c-icon">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <div className="c-info text-start width-80">
                  <h4>Phone</h4>
                  <p>{contact?.phone}</p>
                </div>
              </a>
            </Col>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default ContactUs;
