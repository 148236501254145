import React from "react";
import Dentist from "../../../components/Dentist/Dentist";
import Feature from "../../../components/Feature/Feature";
import LatestBlog from "../../../components/LatestBlog/LatestBlog";
import Service from "../../../components/Service/Service";
import About from "../About/About";
import Promo from "../Appoinment-promo/Promo";
import Appoinment from "../Appoinment/Appoinment";
import Banner from "../Banner/Banner";
import Gallery from "../Gallery/Gallery";
import Slick from "../Slick/Slick";
import Testimonial from "../Testimonial/Testimonial";

const Home = ({ landingPageData, id }) => {
  return (
    <>
      {landingPageData?.Header && (
        <Banner banner={landingPageData.Header} id={id} />
      )}
      {landingPageData?.Features && (
        <Feature features={landingPageData.Features} />
      )}
      {landingPageData?.About && (
        <About
          about={landingPageData.About}
          achievements={landingPageData.Achievements}
        />
      )}
      {landingPageData?.Services && (
        <Service services={landingPageData.Services} />
      )}
      {landingPageData?.Team && <Dentist team={landingPageData.Team} />}
      {landingPageData && <Appoinment landingPageData={landingPageData} />}
      {landingPageData?.Gallery && (
        <Gallery gallery={landingPageData.Gallery} />
      )}
      {landingPageData?.Testimonials && (
        <Testimonial testimonials={landingPageData.Testimonials} />
      )}
      {landingPageData && <LatestBlog landingPageData={landingPageData} />}
      {landingPageData && <Promo id={id} />}
      {landingPageData && <Slick landingPageData={landingPageData} />}
    </>
  );
};

export default Home;
