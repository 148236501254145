import React from "react";
import AboutService from "../../../components/AboutService/AboutService";
import LatestBlog from "../../../components/LatestBlog/LatestBlog";
import Appoinment from "../../Home/Appoinment/Appoinment";
import Content from "../AboutContent/Content";
import Achievement from "../Achievement/Achivement";
import Banner from "../Banner/Banner";
import Dentist from "../ExpertDentist/Dentist";

const About = ({ landingPageData, id }) => {
  return (
    <>
      <Banner landingPageData={landingPageData} id={id} />
      <Content about={landingPageData.About} />
      <AboutService focus={landingPageData.Focus} />
      <Dentist about={landingPageData.About} />
      <Achievement achievements={landingPageData.Achievements} />
      <LatestBlog landingPageData={landingPageData} />
      <Appoinment landingPageData={landingPageData} />
    </>
  );
};

export default About;
