import React from "react";
import Appoinment from "../../../Pages/Home/Appoinment/Appoinment";
import Banner from "../Banner/Banner";
import Detail from "../Detail/Detail";
import Safety from "../Safety/Safety";

const Service = ({ landingPageData, id }) => {
  return (
    <>
      <Banner id={id} />
      {landingPageData?.Services && (
        <Detail services={landingPageData?.Services} />
      )}
      {landingPageData?.SafetyFirst && (
        <Safety safety={landingPageData?.SafetyFirst} />
      )}
      <Appoinment />
    </>
  );
};

export default Service;
