import React from "react";
import Appoinment from "../../../Pages/Home/Appoinment/Appoinment";
import Banner from "../Bannner/Banner";
import Details from "../Details/Details";

const Dentist = ({ landingPageData, id }) => {
  return (
    <>
      <Banner id={id} />
      <Details dentists={landingPageData.Team} />
      <Appoinment />
    </>
  );
};

export default Dentist;
