import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import expertDentist from "../../../Images/experienceddentist.png";
import "./Dentist.css";

const Dentist = ({ about }) => {
  return (
    <section className="expert-dentist">
      <Container>
        <Row className="align-items-center">
          <Col lg={6}>
            <img
              src={expertDentist}
              alt="expertDentist"
              className="img-fluid"
            />
          </Col>
          <Col lg={6}>
            <div className="expertDentist-txt mt-5 mt-lg-0">
              <h2>Experienced Dentist</h2>
              {about.paragraphBottom}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Dentist;
