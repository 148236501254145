import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Achivement.css";

const Achievement = ({ achievements }) => {
  return (
    <section className="achivement-wrap text-white">
      <Container>
        <Row className="align-items-center">
          <Col md={6} lg={6} sm={12}>
            <div className="section-title">
              <h1>Our Achievement</h1>
            </div>
            <div className="achivement-txt">
              <p>{achievements?.paragraph}</p>
              <div className="more-tool">
                {/* <Link to="#.">
                  <button className="theme-btn btn-fill">Appoinment</button>
                </Link> */}
                <a href="#." className="watchBtn">
                  <button className="theme-btn btn-unfill">
                    <FontAwesomeIcon className="play-btn" icon={faPlayCircle} />
                    <span>Watch Video</span>
                  </button>
                </a>
              </div>
            </div>
          </Col>
          <Col md={6} lg={6} sm={12}>
            <Row className="achivement-funfact text-white">
              <Col sm={6} className="text-center">
                <div className="single-funfact">
                  <span>{achievements?.patients?.replace("+", "")}+</span>
                  <p>Patients</p>
                </div>
              </Col>
              <Col sm={6} className="text-center">
                <div className="single-funfact">
                  <span>{achievements?.dentists?.replace("+", "")}+</span>
                  <p>Dentist</p>
                </div>
              </Col>
              <Col sm={6} className="text-center">
                <div className="single-funfact">
                  <span>{achievements?.awards?.replace("+", "")}+</span>
                  <p>Awards</p>
                </div>
              </Col>
              <Col sm={6} className="text-center">
                <div className="single-funfact">
                  <span>{achievements?.branch?.replace("+", "")}+</span>
                  <p>Branch</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Achievement;
