import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import "./App.css";
import About from "./Pages/About/About/About";
import Approved from "./Pages/Approved/Approved";
import Contact from "./Pages/Contact/Contact/Contact";
import Dentist from "./Pages/Dentist/Denitst/Dentist";
import Footer from "./Pages/Home/Footer/Footer.jsx";
import Header from "./Pages/Home/Header/Header.jsx";
import Home from "./Pages/Home/Home/Home.jsx";
import NotFound from "./Pages/NotFound/NotFound";
import Service from "./Pages/Services/Service/Service";
import Spinner from "react-bootstrap/Spinner";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  const [landingPageData, setLandingPageData] = useState(null);
  const [pageError, setPageError] = useState(null);
  const [pageErrorMessage, setPageErrorMessage] = useState(null);
  // get user id/code
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const userId = urlParams.get("id");
  const subdomain = window.location.hostname.split(".");
  if (subdomain[0] === "localhost") {
    subdomain.length = 0;
  }

  async function fetchBusinessInformation() {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/business-info-get/${userId}/${subdomain[0]}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 400 || response.status === 404) {
      const { error } = await response.json();
      setPageErrorMessage(error);
      setPageError(true);
    }

    const info = await response.json();

    setLandingPageData(info);
  }

  useEffect(() => {
    if (userId && subdomain) {
      fetchBusinessInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !pageError ? (
    <HelmetProvider>
      <div className="App">
        <Router>
          {userId ? (
            landingPageData ? (
              <>
                <Helmet>
                  <meta
                    name="description"
                    content={landingPageData?.Meta?.description}
                  />
                  <title>{landingPageData?.Meta?.title}</title>
                </Helmet>
                <Header
                  websiteName={landingPageData?.WebsiteName}
                  id={userId}
                />
                <Routes>
                  <Route
                    path="/"
                    element={<Home landingPageData={landingPageData} />}
                  />
                  <Route
                    path="/home"
                    element={
                      <Home landingPageData={landingPageData} id={userId} />
                    }
                  />
                  <Route
                    path="/about"
                    element={
                      <About landingPageData={landingPageData} id={userId} />
                    }
                  />
                  <Route
                    path="/service"
                    element={
                      <Service landingPageData={landingPageData} id={userId} />
                    }
                  />
                  <Route
                    path="/dentist"
                    element={
                      <Dentist landingPageData={landingPageData} id={userId} />
                    }
                  />
                  <Route
                    path="/contact"
                    element={
                      <Contact landingPageData={landingPageData} id={userId} />
                    }
                  />
                  <Route path="/approved" element={<Approved />} />
                  {/* <Route path="/login" element={<Login />} /> */}
                  <Route path="/*" element={<NotFound />} />
                </Routes>
                <Footer landingPageData={landingPageData} id={userId} />
              </>
            ) : (
              <Spinner
                animation="grow"
                style={{ height: "5em", width: "5em", margin: "auto" }}
              />
            )
          ) : (
            "No ID provided in query"
          )}
        </Router>
      </div>
    </HelmetProvider>
  ) : (
    <div className="App">{pageErrorMessage}</div>
  );
}

export default App;
