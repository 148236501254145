import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Content.css";

const Content = ({ about }) => {
  return (
    <section className="about-content-sec">
      <Container>
        <Row>
          <Col md={12} lg={8} lg={{ order: 2 }} className="text-center">
            <div className="section-title">
              <h1>Our Dental Practice</h1>
            </div>
            <p className="w-50 m-auto content-inner">{about.paragraphTop}</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Content;
