import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Services from "../../Pages/Home/Services/Services";

const Service = ({ services }) => {
  // List of image URLs
  const images = [
    "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Services/dentistry.svg",
    "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Services/selant.svg",
    "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Services/dictionary.svg",
    "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Services/implants.svg",
    "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Services/oral.svg",
    "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Services/general.svg",
  ];

  const numServicesToDisplay = Math.min(
    services.length,
    Math.floor(services.length / 3) * 3
  );

  const servicesToDisplay = services.slice(0, numServicesToDisplay);

  // Append the img key to each service and ensure the images repeat
  const servicesWithImages = servicesToDisplay.map((service, index) => ({
    ...service,
    icon: images[index % images.length],
  }));

  return (
    <section className="service-wrapper">
      <Container>
        <Row>
          <Col sm={12}>
            <div className="section-title text-center">
              <h1>Treatments</h1>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center g-3">
          {servicesWithImages.map((treatment, index) => (
            <Services key={index} treatment={treatment} />
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Service;
