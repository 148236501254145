import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Details.css";

const Details = ({ dentists }) => {
  // List of image paths
  const images = [
    "/assets/images/dentist_1.jpg",
    "/assets/images/dentist_2.jpg",
    "/assets/images/dentist_3.jpg",
  ];

  return (
    <>
      <section className="dentist-details-sec">
        {dentists.map((dentist, index) => (
          <Container key={index}>
            <Row style={{ marginBottom: "2em" }}>
              <Col md={7} lg={8}>
                <div className="single-dentist-details">
                  <h2>
                    {dentist?.name} <span>({dentist?.job})</span>
                  </h2>
                  <p>{dentist?.description}</p>
                </div>
                <div className="dentist-award">
                  <h3>Dental Associations</h3>
                  <ul className="p-0">
                    {dentist?.dentalAssociations?.map((el, idx) => (
                      <li key={idx}>{el}</li>
                    ))}
                  </ul>
                </div>
              </Col>
              <Col md={5} lg={4}>
                <div className="dentist-profile text-center">
                  <div
                    className="profile-img"
                    style={{
                      backgroundImage: `url(${images[index % images.length]})`,
                    }}
                  ></div>
                  <p>
                    Name: <strong>{dentist?.name}</strong>
                  </p>
                  <p>
                    Specialization: <strong>{dentist?.job}</strong>
                  </p>
                  {/* <p>
                    Phone: <strong>1-866-764-5387</strong>
                  </p> */}
                  {/* <div className="doctors-social">
                    <a href=".#">
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a href=".#">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a href=".#">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </div> */}
                </div>
              </Col>
            </Row>
          </Container>
        ))}
      </section>

      {/* <section className="pb-5">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="achivement-img-bg"></div>
            </Col>
            <Col lg={6}>
              <div className="expertDentist-txt mt-5 mt-lg-0">
                <h2>Experienced Dentist</h2>
                <p>
                  Smiling comes naturally to Dr. Harrie, author of ‘Donto’. He
                  has embraced Cosmetic Dentistry and has redesigned the smiles
                  for thev thousands of patients.
                </p>
                <p>
                  Dr. Harrie believes in providing her patients with more than
                  just world class dental care. He also helps patients recognize
                  the vital connection between dental health and whole body
                  health. A graduate of the University of California’s School of
                  Dentistry, Dr. Harrie is a leader in the movement to bring
                  environmental sanity and well-being into the dental world for
                  future.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
    </>
  );
};

export default Details;
