import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import serviceDetailImg from "../../../Images/service-details-promo1.png";
import serviceDetailImg2 from "../../../Images/service-details-promo2.png";
import "./Detail.css";

const Detail = ({ services }) => {
  return (
    <>
      {services.map((service, index) => {
        if (index % 2 === 0) {
          return (
            <section className="healing p-5" key={`section1-${index}`}>
              <Container>
                <Row className="align-items-center p-5">
                  <Col lg={6} sm={12} xs={12}>
                    <div className="expertDentist-txt mt-5 mt-lg-0">
                      <h2 className="fw-bold">{service.name}</h2>
                      <p>{service.text}</p>
                    </div>
                  </Col>
                  <Col lg={6} sm={12} xs={12}>
                    <img
                      src={serviceDetailImg}
                      alt="serviceDetailImg"
                      className="img-fluid"
                    />
                  </Col>
                </Row>
              </Container>
            </section>
          );
        } else {
          return (
            <section
              className="healing p-5 footer-bg"
              key={`section2-${index}`}
            >
              <Container>
                <Row className="align-items-center p-5">
                  <Col lg={6} sm={12} xs={12}>
                    <img
                      src={serviceDetailImg2}
                      alt="serviceDetailImg2"
                      className="img-fluid pt-xs-5"
                    />
                  </Col>
                  <Col lg={6} sm={12} xs={12}>
                    <div className="expertDentist-txt mt-5 mt-lg-0">
                      <h2 className="fw-bold text-white">{service.name}</h2>
                      <p className="text-white">{service.text}</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          );
        }
      })}
    </>
  );
};

export default Detail;
