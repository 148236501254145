import React from "react";
import Appoinment from "../../../Pages/Home/Appoinment/Appoinment";
import Banner from "../Banner/Banner";
import ContactUs from "../ContactUs/ContactUs";

const Contact = ({ landingPageData, id }) => {
  return (
    <>
      <Banner id={id} />
      <ContactUs contact={landingPageData.Contact} />
      <Appoinment />
    </>
  );
};

export default Contact;
