import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Header.css";

const Header = ({ websiteName, id }) => {
  return (
    <div className="head-bg">
      <Navbar className="navbar" collapseOnSelect expand="lg">
        <Container className="container-head">
          <Navbar.Brand href={`/?id=${id}`} style={{ color: "#fc5185" }}>
            {websiteName}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" expand="lg" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto align-items-center">
              <Link
                to={`/home?id=${id}`}
                className="list-item text-decoration-none"
              >
                Home
              </Link>
              <Link
                to={`/about?id=${id}`}
                className="list-item text-decoration-none"
              >
                About
              </Link>
              <Link
                to={`/service?id=${id}`}
                className="list-item text-decoration-none"
              >
                Service
              </Link>
              <Link
                to={`/dentist?id=${id}`}
                className="list-item text-decoration-none"
              >
                Dentist
              </Link>
              <Link
                to={`/contact?id=${id}`}
                className="list-item text-decoration-none"
              >
                Contact
              </Link>
              {/* {user.email 
                            ?
                            <button type="button" className="btn btn-danger" onClick={logout}>Log Out</button>
                            :
                            <Link to="/login" type="button" className="btn btn-danger">Login</Link>
                            }
                            {user.email &&
                                <Navbar.Text><FontAwesomeIcon icon={faUser} /><span className="userName">{user.displayName}</span></Navbar.Text>
                            } */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
