import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
// import client from "../../../Images/testi1.png";
import "./Testimonial.css";

const Testimonial = ({ testimonials }) => {
  const images = [
    "/assets/images/testimonial_1.jpg",
    "/assets/images/testimonial_2.jpg",
    "/assets/images/testimonial_3.jpg",
  ];

  const testimonialWithImages = testimonials.map((testimonial, index) => ({
    ...testimonial,
    img: images[index % images.length],
  }));

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    AOS.refresh();
  }, []);

  return (
    <section className="testimonial-wrapper">
      <Container>
        <Row>
          <Col sm={12}>
            <div className="section-title">
              <h1>Testimonials</h1>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center g-3">
          {testimonialWithImages.map((testimonial, index) => (
            <Col md={6} lg={4} sm={12} key={index}>
              <div className="client-box text-center" data-aos="fade-right">
                <div className="client-img">
                  <img src={testimonial.img} alt="" />
                </div>
                <h3 className="mt-4">{testimonial.name}</h3>
                <span>{testimonial.text}</span>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Testimonial;
